.fp-text-title-1 {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;

  &.bold {
    font-weight: 700;
  }
}

.fp-text-title-2 {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;

  &.bold {
    font-weight: 700;
  }
}

.fp-text-title-3 {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;

  &.bold {
    font-weight: 700;
  }
}

.fp-text-sub-heading {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  &.bold {
    font-weight: 700;
  }
}

.fp-text-sub-heading-1 {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  &.bold {
    font-weight: 700;
  }
}

.fp-text-body {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  &.bold {
    font-weight: 700;
  }
}

.fp-text-label {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  &.bold {
    font-weight: 700;
  }
}

.fp-text-caption {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  &.bold {
    font-weight: 700;
  }
}

.fp-filter-label {
  margin: 10px 0px 5px 0px !important;
  font-family: 'Nunito Sans', sans-serif;
  color: #373737;
  font-size: 16px;
  font-weight: 500;
}

.disclaimer-msg {
  margin: 10px 0px 5px 20px !important;
  font-family: 'Nunito Sans', sans-serif;
  color: $danger !important;
  font-size: 12px;
  font-weight: 500;
}
