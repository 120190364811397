.input-sm {
  height: 35px !important;
  width: 90% !important;
}

.MuiMenuItem-root {
  height: 30px !important;
}

.MuiTypography-displayBlock {
  font-size: 15px !important;
}

.custom-table {
  .react-grid-Container {
    width: 100% !important;
  }

  .react-grid-Canvas {
    width: 100% !important;
    overflow: auto !important;
  }

  .react-grid-Cell {
    border: none !important;
    // border-bottom: 1px solid #dddddd !important;
    border-right: 1px solid var(--color) !important;
    border-bottom: 1px solid var(--color) !important;
  }

  .react-grid-Main {
    height: 100% !important;
    outline: none !important;
  }

  .react-grid-Container {
    height: 100% !important;
  }

  .react-grid-Grid {
    min-height: 100% !important;
    border: 1px solid #ffffff !important;
  }

  .react-grid-HeaderRow {
    width: 100% !important;
  }

  .react-grid-Canvas {
    height: 100% !important;
  }

  .no-side-padding {
    padding: 0 !important;
  }

  .react-grid-HeaderCell {
    border: 0;
    box-shadow: none;

    background-color: var(--color) !important;
    color: white !important;
  }

  .react-grid-HeaderCell--frozen:last-of-type {
    box-shadow: none;
  }

  .react-grid-Header {
    background-color: transparent !important;
  }
}

.checkbox_dataset .MuiIconButton-label {
  color: #5dd987 !important;
}
