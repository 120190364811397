.report-list-bar {
  background-color: #8c8c8c44;
  height: 100%;
}

.fp-btn-report {
  color: black !important;
  margin: 0.5rem 1rem !important;
  box-shadow: 0px 2px 6px gainsboro;
  width: fit-content;
  font-size: 16px;
  padding: 3px 8px;
  text-transform: capitalize !important;
}

.fp-btn-report:hover {
  box-shadow: 0px 2px 6px gainsboro;
}

.report-list-bar {
  background-color: #8c8c8c44;
  height: 100%;
}

.no-reports {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 70%;
    width: 60%;
  }
}

.report-list {
  display: flex;
  width: 100%;
  height: 85%;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;

  .active-card {
    border: 3px solid #4caf50 !important;
  }

  .report-card-min {
    display: flex;
    background-color: white;
    width: 100%;
    flex-direction: column;
    cursor: pointer;
    border: 3px solid transparent;
    border-radius: 5px;
    padding: 5px;
    margin: 5px 0px;

    label {
      cursor: pointer;
      color: #262626;
    }
  }
}

.report-box {
  width: calc(100vw - 400px);
  text-align: center;
  padding-top: 2px;
  background-color: white;
  overflow: auto;
}

.report-title-bar {
  color: $black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 0px 0px;
  &:hover {
    svg {
      fill: $primary-color;
    }
  }
}

.report-title {
  margin: 2px;
  padding-left: 5px;
  font-size: 1.2rem;
}

.report-filter-bar {
  display: flex;
  width: 100%;
  height: 46px;
  align-items: center;
  padding: 8px 10px;
  background-color: white;
  .report-filter-container {
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: flex-end;
    float: right;
  }
}

.date-divider {
  margin-top: 5px;
}

.secondary-btn {
  border: #4caf50;
  background-color: white;
  color: #262626;
}

.danger-btn {
  background-color: #ef476f;
  color: white;
}

// reports v2

.reports-pagearea {
  min-height: 500px;
  position: relative;
  padding: 20px;
  background: #fff;
  margin-top: 1%;
  width: 100%;
  height: inherit;
  overflow-y: auto;

  .report_category {
    text-align: start;

    .report_icon {
      // overflow: hidden;
      list-style: none;
      // padding: 0px 20px;
      display: flex;
      flex-wrap: wrap;
      // display: block !important;
      // margin-left: -32px;
      margin-bottom: 1rem;

      .report-icon-text {
        color: #183247;
      }

      li {
        float: left;
        padding: 0 18px;
        position: relative;
        min-height: 130px;

        a {
          position: relative;
          display: block;
          padding: 8px 0 2px 0;
          text-align: center;
          text-decoration: none;
          color: #183247;
          width: 100px;

          .img-outer {
            height: 60px;
            width: 60px;
            margin: 0 auto;
            padding: 1px;
            border-radius: 5px;
            margin-bottom: 5px;
            background-color: $primary-light;
          }

          &:hover {
            .fp-text-label {
              font-weight: 700;
              color: $primary-color;
            }
            .img-outer {
              background-color: $primary-color;
              color: #ffffff;
              img {
                filter: invert(1);
              }
            }
          }
        }
      }
    }
  }
  .title {
    // border-top: 1px solid $light-grey;
    margin-bottom: 1.5rem;
    margin-left: 16px;

    span {
      border-bottom: 3px solid $primary-light;
      display: inline-block;
      margin-top: 2px;
      color: #121212;
    }
  }
}

.report-conditions {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  background-color: $emphasis;
  padding: 5px;
}

@media screen and (max-width: 600px) {
  .report-title {
    width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
