.live-location {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #17263c;
  // background-color: rgba(19,207,100,.17);
}

.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.live-header {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  z-index: 30;
  box-shadow: 0px 1px 6px 2px rgba(240, 240, 231, 0.527);
}

.user-box {
  display: flex;
  width: 50%;
  color: #4caf50;
}

.expiry {
  font-size: medium;
  color: #4caf50;
  width: 50%;
  padding: 5px;
  text-align: right;
}

.live-map {
  width: 100%;
  height: 100%;
  background-color: gray;
}

.live-avatar {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.live-footer {
  z-index: 30;
  padding: 10px;
  width: 100%;
  display: flex;
  box-shadow: 0px 3px 6px rgb(240, 240, 231);
  font-weight: bolder;
  background-color: #17263c;
  // background-color: rgba(19,207,100,.17);
}

.refresh-btn {
  // background-color: #4CAF50;
  border-radius: 10%;
  box-shadow: 0px 4px 6px #faf4f429;
}

.pulse {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: '';
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -50px 0 0 -9px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px white;
  // box-shadow: 0 0 1px 2px #89849b;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

.error-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;

  .expiredimg {
    height: 50px;
    width: 50px;
  }
}
