.fp-table-actions-container {
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.fp-table-actions-spacer {
  width: 16px;
}

.fp-table-download-dropdown {
  position: absolute;
  right: 8px !important;
  z-index: 20;
  background-color: #ffffff;
  padding-top: 16px;
  border-radius: 5px;
  box-shadow: -1px 1px 10px 0px rgba(0, 0, 0, 0.32);
  div {
    p {
      line-height: 30px;
      margin-bottom: 12px;
      padding: 0px 24px;
      cursor: pointer;
    }
  }
}

.fp-table-download-format {
  &:hover {
    background-color: $primary-color-hover;
  }
}

// No data
.fp-table-no-data-container {
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    padding: 8px 16px;
    border-radius: 18px;
    font-size: 16px;
    font-weight: 700;
    background-color: $primary-color-hover;
  }
}

// Table
.fp-table-container {
  overflow-x: scroll;
  font-size: 15px;
  &.withShadow {
    box-shadow: -1px 1px 34px 0px rgba(0, 0, 0, 0.32);
  }
  table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
  }
}

.fp-table-header {
  position: sticky;
  top: 0;
  height: 38px;
  z-index: 10;
}

.fp-table-header-cell {
  min-width: 180px !important;
  height: 100%;
  background-color: $primary-color;
  border: 0.5px solid rgba(0, 0, 0, 0.32);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  abbr {
    text-align: center;
    color: #ffffff;
    max-width: 250px !important;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-decoration: none !important;
    cursor: auto !important;
  }

  &.clickable {
    cursor: pointer;
  }
}

.fp-table-sticky-header {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  z-index: 20;
}

.fp-table-sort-icon {
  color: #ffffff;
  cursor: pointer;
}

.fp-table-body-container {
  overflow-y: scroll;
  table {
    overflow-y: visible;
  }
}

.fp-table-body {
  height: 40px;
  &:hover {
    background-color: $primary-color-hover;
  }
}

.fp-table-body-cell {
  vertical-align: middle !important;
  width: 180px !important;
  border: 0.5px solid rgba(0, 0, 0, 0.32);
}

.fp-table-sticky-body {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  z-index: 19;
  border: 0.5px solid rgba(0, 0, 0, 0.32);
  background-color: #ffffff;
  &:hover {
    background-color: $primary-color-hover;
  }
}

// Cell Types
.fp-table-text-cell {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.fp-table-location-cell {
  cursor: pointer;
  color: $color-blue;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.fp-table-rating-cell {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.fp-table-status-cell {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  p {
    border-radius: 15px;
    padding: 5px 10px;
    color: #ffffff;
    background-color: transparent;
    &.pending {
      background-color: $warning;
    }
    &.in-progress {
      background-color: $secondary-color;
    }
    &.completed {
      background-color: #65bb7f;
      color: white;
    }
    &.failed {
      background-color: $danger;
    }
    &.delay {
      background-color: grey;
    }
  }
}

// Edit and Delete Actions
.fp-table-row-action-cell {
  height: 100%;
  vertical-align: middle !important;
  width: 180px !important;
  border: 0.5px solid rgba(0, 0, 0, 0.32);
  display: flex;
  justify-content: center;
  align-items: center;
}

// Summary Section
.fp-table-summary {
  height: 40px;
}

.fp-table-summary-cell {
  vertical-align: middle !important;
  height: 40px;
  width: 180px !important;
  justify-content: center;
  border: 0.5px solid rgba(0, 0, 0, 0.32);
  background-color: #f7f7f7;
  div {
    float: right;
    padding: 0px 8px;
  }
}

// Pagination
.fp-table-pagination-container {
  position: relative;
  margin-top: 24px;
  height: 50px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  overflow: auto;
}
