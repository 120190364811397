.searchIdentifierBox {
  width: 100%;
  padding: 2%;

  input {
    width: 96%;
    height: 30px;
    border: none;
    border-bottom: 2px solid #8c8c8c;
  }
}

.identifier-list-selector {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 600px;
  overflow-y: auto;
  padding: 10px;

  div {
    background-color: white;
    text-align: center;
    border: 1px solid black;
    margin: 5px 0px;
    cursor: pointer;
  }

  div:hover {
    box-shadow: 0px 2px 6px #8c8c8c;
  }
}

.cancelled-identifier {
  background-color: gainsboro !important;
  color: white;
  cursor: not-allowed !important;
}

.status-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  .custom-status {
    margin: 3px;
    p {
      width: 150px !important;
      padding: 2px 5px;
    }
  }
}

.share-form-modal {
  width: 100%;
  padding: 10px;
}

.share-form-switch {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.share-link-input {
  border: none;
  border-bottom: 2px solid $grey;
  outline: none;
  margin-left: 10px;
}

.shareFormFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid $emphasis;
  background-color: $emphasis;
  padding: 0px;
  height: 40px;
  width: 100%;

  .copy-link-holder {
    background-color: $primary-color;
    color: white;
    align-items: center;
    cursor: pointer;
    padding: 6px 10px;
    height: inherit;
  }
}

.generate-link-bar {
  text-align: right;
}

.share-form-entry-bar {
  display: flex;
  align-items: baseline;
}
