.team-table .team-table-border {
  border-right: 1px solid var(--color) !important;
  border-left: 1px solid var(--color) !important;
  border-top: 1px solid var(--color) !important;

  padding-top: 0 !important;
  padding-bottom: 0 !important;
  text-align: center !important;
}

.team-table .team-table-border-last {
  border-right: 1px solid var(--color) !important;
  border-left: 1px solid var(--color) !important;
  border-top: 1px solid var(--color) !important;
  border-bottom: 1px solid var(--color) !important;
  text-align: center !important;
}

.team-table .material-table__cell--sort {
  border-right: 1px solid #bdbdbd !important;
  border-left: 1px solid #bdbdbd !important;
}

.team-table .material-table__cell--sort {
  padding: 0 10px !important;
}

.team_prof .dropzone.dropzone--single .dropzone__img img {
  height: 150px !important;
  border-radius: 0px !important;
  width: auto !important;
}

.team-table .material-table__cell--sort {
  border-left: 0 !important;
  border-right: 0 !important;
}
