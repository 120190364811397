.checkbox-color {
  width: 38px;
  height: 21px;

  .switch-toggle {
    width: 19px;
    height: 19px;
  }
}

.checkbox-color.on .switch-toggle {
  left: 17px;
}

.settings-item-row {
  width: -webkit-fill-available;
  padding: 10px 0px;
  margin: 0 !important;
  align-items: center;

  padding-bottom: 5%;
  padding-top: 5%;

  .col-sm-9 {
    padding: 0;
  }

  .col-sm-3 {
    padding: 0;
  }
}

.react-select-column {
  .react-select__control {
    border: 5px solid #f2f4f7;
    background-color: white;
  }
}

.settings-item-row-dynamic {
  width: -webkit-fill-available;
  padding: 10px 0px;
  margin: 0;
  align-items: center;
  border-bottom: 0px solid #e8e8e8;
  padding-bottom: 5%;
  padding-top: 5%;

  .col-sm-9 {
    padding: 0;
  }

  .col-sm-3 {
    padding: 0;
  }

  .MuiFormControlLabel-labelPlacementBottom {
    margin: 0;
  }

  .MuiFormGroup-row {
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }
}

.backgrounddd {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
}

.setting-radio-group {
  .PrivateSwitchBase-root-329 {
    padding: 0;
  }
}

.checkbox-btn--checkbox-color {
  display: inline-block;
}

.margin-0 {
  margin: 0 !important;
  width: 100%;
}

.create-question-head,
.create-question-decription {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.question-badge {
  display: flex;
  min-width: 20px;
  padding: 3px 0px;
  justify-content: center;
  align-items: center;
  span {
    margin-left: 5px;
  }
  img {
    width: 10px;
    height: 10px;
  }
}

.create-tab-settings-list {
  width: 100%;

  .MuiListItem-dense {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

.create-question-item-container-box {
  .copy-delete-icon {
    display: none;
  }
}

.create-question-item-container-box:hover {
  .copy-delete-icon {
    display: flex;
    width: 50px;
  }
}

.settings-item-row-dynamic.task-settings {
  textarea,
  textarea:focus {
    border: 0;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiListItemSecondaryAction-root {
    .react-datepicker__input-container,
    .form__form-group-datepicker {
      border: 1px solid #98999a;
      color: #646777;
    }
  }
}

.react-datepicker__input-container,
.form__form-group-datepicker {
  border: 1px solid #98999a;
  color: #646777;
}

//////// workflow

.workflow-sidebar-button {
  width: 100%;
  cursor: pointer;
  text-align: center;
  padding: 6px 10px;
  border: 1px solid #14c74e;
  border-radius: 5px;
  margin: 10px 20px;
  color: #373737;
}

.workflow-taskselect-card {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  margin: 15px 5px;
  padding: 5px 5px;
  display: flex;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;

  &:hover {
    color: #13c46f;
  }
  .taskcolorbox {
    width: 20px;
    height: 20px;
    display: inline-block;

    border-radius: 3px;
    margin-right: 10px;
  }

  .delete-icon {
    color: black;
    line-height: 30px;
  }
}
.new-subgroup-box {
  padding: 5px 5px;
  background: #13c64f;
  color: white;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.taskcolorbox-taskcanvas {
  background-color: transparent;
  width: 25%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
}

.taskname-taskcanvas {
  width: 100%;
  font-size: 14px;
  justify-content: center;
  height: 100%;
  text-align: center;

  color: white;
}

.pdf-pdfcanvas {
  width: 100%;
  font-size: 14px;
  justify-content: center;
  height: 100%;
  text-align: center;

  color: white;
}

.taskicon-taskcanvas {
  // margin-top: 34px;
  background: white;
  color: #7b7b7b;
  border-radius: 50%;
}

.workflow-row {
  font-size: 13px;
}

.workflow-row-tasknode {
  .deleteicon-taskcanvas {
    display: none;
  }

  &:hover {
    .deleteicon-taskcanvas {
      display: block;
    }
  }
}

#octagon {
  width: 100px;
  height: 100px;
  background: white;
  position: relative;
}
#octagon:before {
  content: '';
  width: 100px;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 29px solid white;
  border-left: 29px solid #f3f4f7;
  border-right: 29px solid #f3f4f7;
}
#octagon:after {
  content: '';
  width: 100px;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 29px solid white;
  border-left: 29px solid #f3f4f7;
  border-right: 29px solid #f3f4f7;
}

.create-questions-header {
  padding: 10px 31px;
  border-bottom: 1px solid #c9c8c8;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.create-info-tab {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 5px;
  color: white;
  float: right;
  font-size: 14px;
  text-transform: uppercase;
}

.new-subgroup-box {
  margin-top: 10px;
}

.create-question-placeholder:empty:before {
  content: attr(data-placeholder);
  color: #bcc0c5;
}

.addOnContainer {
  // min-height: 200px;
  border-top: 2px solid $emphasis;
  background-color: $primary-light;
  margin-top: 10px;
}
