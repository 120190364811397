.red {
  color: red !important;
}

.topbar-taskicon {
  margin: auto;

  margin-top: 17px;

  padding: 1px;
  border: 1px solid #96969694;
}

.taskname-input-div {
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0);
  }

  label {
    color: transparent;
  }

  :hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.23);
    }

    label {
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.allocations-datefrequency {
  label {
    font-size: 10;
  }

  .MuiTypography-body1 {
    font-size: 13px;
    font-weight: 400;
  }
}

.dropdown,
.dropdown:hover,
.dropdown:focus {
  .btn.btn-secondary {
    background-color: inherit !important;
    border-color: white !important;
    color: white !important;
    padding: 10px;

    &:before {
      background-color: inherit !important;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      background-color: inherit !important;
      color: #ffffff !important;
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
      background-color: inherit !important;
      background-color: inherit !important;
    }
  }

  .subDropdown.btn.btn-secondary {
    background-color: white !important;
    border-color: white !important;
    color: white !important;
    padding: 0px;

    &:before {
      background-color: #ffffff !important;
    }

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
      border-color: #ffffff !important;
      color: #ffffff !important;
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
      background-color: #ffffff !important;
      border-color: #ffffff !important;
    }
  }

  .dropdown-menu {
    min-width: 5rem;
    // padding: 0.7rem 0.5rem;
    // margin: 0.125rem 0 0;

    .dropdown-item {
      display: block;
      width: 100%;
      padding: 0.55rem 0.55rem;
      clear: both;
      font-weight: 400;
      color: #212529;
      text-align: center;
      white-space: nowrap;
      border: 0;
    }
  }
}

.btn.btn-secondary:focus:hover,
.btn.btn-secondary:hover,
.btn.btn-secondary:active,
.btn.btn-secondary:active:focus {
  background-color: white !important;
  border-color: white !important;
  color: white !important;
  padding: 10px;
}

.full-height-tab {
  height: calc(100vh - 65px);
  background: white;
  overflow: auto;
}

.no-padding {
  padding: 0;
}
