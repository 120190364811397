.profile-container {
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: space-around;

  input {
    border: none;
    border-bottom: 1px solid gainsboro;
    outline: none;
    width: 90%;
  }
}

.profilediv {
  background-color: white;
  height: inherit;
  width: 300px;
  box-shadow: 0px 3px 6px #00000029;
}

.toggler {
  text-align: right;
  height: 30px;
}

.profileInsights {
  height: inherit;
  width: 100%;
  display: flex;

  padding: 10px;
  margin: 10px;

  justify-content: space-evenly;
  height: 85vh;
}

.profileAnalytics {
  height: 120px;
  display: flex;
  justify-content: space-around;
  div {
    width: 20%;
    border-radius: 28px;
    background-color: white;
    height: inherit;
    padding: 2%;
    box-shadow: 0px 3px 6px #00000029;

    p {
      font-size: 2.3rem;
      font-weight: bolder;
      text-align: center;
    }
  }
}
.profilePic {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.nameField {
  text-align: center;
  margin: 5px;
  p {
    margin-top: 5px;
  }
}

.Profileavatar {
  height: 200px;

  width: 200px;
}

.profileForm {
  padding: 10px;
}

.value-text {
  padding-top: 0px;
  font-size: 0.8rem;
}

.headings {
  font-size: 15px;
  color: black;
}

.paymentStatistics {
  width: 100%;
  height: 200px;
  background-color: white;
  border: 2px solid #4caf50;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
}

.billingTable {
  height: 90%;
  width: 100%;
  background-color: white;
  border: 2px solid #4caf50;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
}

.trialBoard {
  height: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 2px solid #4caf50;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;

  div {
    font-size: 1.5rem;
    margin-top: 10px;

    span {
      color: #4caf50;
    }
  }
}

.reset-btn {
  margin-top: 0.95rem;
  height: 31px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 2px 6px grey;
  text-align: center;
  background-color: white;
  color: #4caf50;
}

.trialmsg {
  color: $primary-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: 16px;
  font-weight: 700;
}

.buffer-alert {
  color: crimson;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 280px;
  font-weight: 700;
}

.add-payment-btn {
  margin-top: 10px;
  border: none;
  background-color: #4caf50;
  font-size: 1.2rem;
  color: white;
  box-shadow: 0px 2px 6px grey;
  padding: 10px;
  border-radius: 5px;
}

.expired-card {
  display: flex;
  height: 90vh;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  div {
    text-align: center;
  }
}

.introbox-1,
.introbox-2,
.introbox-3 {
  margin: 0;
  width: 100%;

  padding: 20px;

  text-align: center;
}

.introbox-4 {
  margin: 0;
  width: 100%;

  padding: 20px;

  text-align: center;
}

.Announcement_Banner {
  background: #fff;
  height: 50px;
  position: relative;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  display: table;
  width: 100%;
}
.Announcement_Banner .title {
  position: relative;
  height: 100%;
  font-size: 50px;
  font-weight: bold;
  color: #fff;
  border-radius: 2px 0 0 2px;
  padding-right: 50px;
}
.Announcement_Banner .content {
  padding: 15px 15px 15px 8px;
  width: 100%;
}
.Announcement_Banner .close-btn {
  cursor: pointer;
  color: currentColor;
  font-size: 28px;
  padding-right: 15px;
}

.Announcement_Banner .title,
.Announcement_Banner .close-btn {
  width: 0;
}
.title,
.Announcement_Banner .content,
.Announcement_Banner .close-btn {
  display: table-cell;
  vertical-align: middle;
}
.Announcement_Banner .close-btn {
  cursor: pointer;
  color: currentColor;
  font-size: 28px;
  padding-right: 15px;
}

.Announcement_Banner .title .title-content {
  position: relative;
  font-size: 15px;
  line-height: 18px;
  height: 100%;
  z-index: 1;
  padding-left: 15px;
  color: #fff;
  white-space: nowrap;
  display: table;
}
.Announcement_Banner .title .title-content .title-content-wrapper {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}

.Announcement_Banner .title:before,
.Announcement_Banner .title:after {
  position: absolute;
  display: block;
  content: '';
}

.Announcement_Banner .title:before {
  bottom: 0;
  width: 60px;
  height: 100%;
  opacity: 0.5;
  right: 25px;
  background-color: currentColor;
  transform: skew(30deg);
}

.Announcement_Banner .title:after {
  top: 0;
  width: 60px;
  height: 100%;
  right: 16px;
  background-color: currentColor;
  z-index: 0;
  transform: skewX(-30deg);
}
.pull-right {
  float: right;
}

.primary {
  color: #528ff0;
}

.btn-support {
  padding: 5px 25px;
  background: #fff;
  border: 1px solid #528ff0;
  margin-right: 10px;
}

//panel

.panel {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 0;
}

.panel-default > .panel-heading {
  color: #333;
  background-color: $primary-color-hover;
  border-color: #eaeff0;
}

.profile-container .panel-heading {
  padding-top: 10px;
  padding-bottom: 10px;
  padding: 15px 20px;
  font-weight: 700;
  border-bottom: 1px solid transparent;
}

.panel > .list-group,
.panel > .panel-collapse > .list-group {
  padding-left: 0;
  margin-bottom: 0;
}

.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #eaeff0;
  border-width: 1px 0;
  border-radius: 0;
}
.list-group .list-group-item:first-child {
  border-top-width: 0;
}

.list-group-item span:nth-of-type(1) {
  font-weight: 600;
}

.details-row-container .list-group-item:not(.no-flex) {
  display: flex;
  justify-content: space-between;
}

.color-blue {
  color: $primary-color !important;
  font-weight: 700;
  // background-color: $primary-color;
  // padding: 6px 16px;
  // border-radius: 15px;
  // :hover {
  //   text-decoration: none !important;
  // }
}

.panel-default {
  border-color: #eaeff0;
}

.profile-payment-statistics-header {
  background-color: $primary-color-hover;
  padding-top: 10px;
  padding-bottom: 10px;
  padding: 15px 20px;
}

.profile-contact-us-container {
  display: flex;
  flex-direction: row;
  background-color: $primary-color-hover;
  border-radius: 15px;
  margin: 12px 0px;
}

.profile-contact-us-btn {
  color: #fff;
  background-color: $primary-color;
  padding: 6px 24px;
  border-radius: 15px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-contact-us-input {
  color: #121212;
  width: 100%;
  min-height: 140px;
  padding: 6px 24px;
  border-radius: 15px;
  font-weight: 400;
}
