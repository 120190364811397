.material-table {
  th {
    white-space: nowrap;
  }
}

.material-table__toolbar-wrap {
  position: absolute;
  top: 10px;
  @include directify($directions) {
    #{directed('right')}: 0px;
  }

  .material-table__toolbar {
    padding: 0 30px;
  }

  .material-table__toolbar-button {
    height: 36px;
    width: 36px;
    padding: 0;
  }
}

.material-table__toolbar-selected {
  @include directify($directions) {
    #{directed('margin-right')}: 10px;
  }
}

.material-table__row {
  margin-bottom: 2rem !important;
  transition: 0.3s;
  cursor: pointer;

  &[aria-checked='true'] {
    @include themify($themes) {
      background-color: themed('colorHover');
    }

    .material-table__checkbox span {
      color: $color-accent;
    }
  }

  &:hover {
    @include themify($themes) {
      background-color: themed('colorBackgroundBody');
    }
  }
}

.material-table__checkbox--checked span {
  color: $color-accent;
}

.material-table__cell-right {
  @include directify($directions) {
    text-align: directed('left');
  }
}

.material-table__cell.material-table__cell--sort {
  span {
    // .MuiRating-root {
    //   color: #ffb400 !important;
    // }

    transition: 0.3s;

    @include themify($themes) {
      // color: themed('colorText');
    }

    &:hover {
      color: $color-accent;
    }
  }
}

.material-table__cell,
.material-table__sort-label,
.material-table__toolbar-button {
  @include themify($themes) {
    color: themed('colorText');
  }
}

.material-table__checkbox {
  transition: 0.3s;

  @include themify($themes) {
    color: themed('colorIcon');
  }
}

.material-table__pagination {
  @include directify($directions) {
    float: directed('right');
  }

  button:hover {
    @include themify($themes) {
      background-color: themed('colorHover') !important;
    }
  }

  & > div {
    padding: 0;
  }

  span,
  div,
  svg {
    @include themify($themes) {
      color: themed('colorText');
    }
  }
}

.material-table__wrap {
  overflow-x: auto;
}

.material-table__filter-menu {
  div:last-child {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }

  .material-table__filter-menu-item {
    color: #646777;
    transition: 0.3s;
    font-size: 13px;
    padding: 7px 24px;
    height: auto;

    &:hover {
      background: #fafbfe;
    }
  }
}

@media screen and (max-width: 768px) {
  .material-table__pagination {
    div {
      margin-left: 8px;
      margin-right: 8px;

      &:last-child {
        @include directify($directions) {
          #{directed('margin-right')}: 0px;
        }
      }

      &:first-child {
        margin: 0;
      }
    }

    div > span:first-of-type {
      display: none;
    }
  }
}

@media screen and (max-width: 420px) {
  .material-table__toolbar-selected {
    span {
      display: none;
    }
  }
}

.MuiTableRow-root .tableHeader {
  border-radius: 7px 0 0 7px !important;
}

.MuiTableRow-root .tableHeader1 {
  border-radius: 0px 7px 7px 0 !important;
}

.MuiTableCell-root {
  border: none !important;
  padding: 12px !important;
}

.dropdown__menu {
  width: 170px;
  border-radius: 0;
  border: none;
  margin: 0;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  font-size: 13px;

  @include themify($themes) {
    background: themed('colorBackground');
  }

  button {
    transition: all 0.3s;
    padding: 7px 24px;
    cursor: pointer;

    @include themify($themes) {
      color: themed('colorText');
    }

    &:hover {
      @include themify($themes) {
        background: themed('colorHover');
        color: themed('colorText');
      }
    }
  }

  button:focus,
  button:active,
  button:active:focus {
    outline: none;
  }

  .active button {
    background-color: $color-blue;

    &:hover {
      background-color: $color-blue-hover;
    }
  }
}

.material-table__row:hover .add_user_task {
  display: block !important;
}

.dashboard__table-more {
  .btn.btn-secondary {
    background-color: inherit;
    border-color: inherit;
    outline: none;
  }

  & > button {
    margin: 0;
    padding: 0 5px;
    border: none;
    background-color: transparent;

    &:before {
      display: none;
    }

    svg {
      margin: 0;
      height: 20px;
      width: 20px;
      fill: $color-additional;
      transition: all 0.3s;
    }

    &:hover,
    &:not([disabled]):not(.disabled):active,
    &:focus {
      background: transparent;

      svg {
        fill: $color-accent;
      }
    }
  }

  & > div {
    min-width: 90px;
    width: 100%;
  }

  button {
    font-size: 13px;
  }
}

.task_button {
  margin-bottom: 0px !important;
}

.task_dropdown {
  margin-right: -0.5rem;
  float: right;
  height: 0px !important;
}

.material-table th {
  white-space: normal !important;
}

@media screen and (max-width: 500px) {
  .toggleMobile {
    display: none !important;
  }
}

.dropdown__menu .taskInactive {
  padding-left: 0.3rem !important;
}

.MuiPaginationItem-textPrimary.Mui-selected {
  background-color: $color-green !important;
}
