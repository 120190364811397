.forms-fp {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: rosybrown;
  padding: 10px;

  .forms-fp-header {
    font-size: 20px;
    width: 100%;
    margin: auto;
    border-radius: 5px;
    background-color: white;
    position: sticky;
    z-index: 100;
    top: 0;
    padding: 5px;
    text-align: center;
    font-weight: bold;
    filter: drop-shadow(0 0 0.55rem rgb(46, 43, 44));
  }

  .forms-questions-wrapper {
    margin: 20px auto;
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 200px;
    filter: drop-shadow(0 0 0.5rem rgb(158, 156, 157));
    padding: 15px;
    padding-bottom: 20px;

    .question-card {
      // border-bottom: 0.5px solid rgba(27, 26, 26, 0.466);
      // box-shadow: 0px 2px 6px rgba(27, 26, 26, 0.466);
      padding: 10px;
    }

    .fp-forms-input {
      width: 80%;
      margin: 15px;
      padding: 5px;
      border: none;
      outline: none;
      border-bottom: 1px solid gainsboro;
    }

    .fp-forms-bold {
      font-weight: bolder;
    }

    .fp-forms-filename {
      background-color: white;
      color: black;
    }
  }

  .fp-forms-question {
    font-size: 1rem;
    margin: 10px 0px;
  }
  .fp-forms-description {
    margin: 15px;
    font-style: italic;
    font-weight: bolder;
  }

  .fp-forms-req {
    color: crimson;
  }

  .fp-form-error {
    color: crimson;
  }

  .fp-forms-checkbox-wrapper {
    padding: 10px;
  }
  .submit-section {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .form-submit-btn {
    background-color: #4caf50;
    border: none;
    border-radius: 10px;
    filter: drop-shadow(0 0 0.5rem rgb(46, 43, 44));
    color: white;
    width: 200px;
    padding: 10px;
    text-align: center;
  }

  .fp-subforms-add-btn {
    border: none;
    border-radius: 4px;
    box-shadow: 0px 2px 6px rgb(46, 43, 44);
    color: #4caf50;
    // float: right;
    padding: 5px;
    margin: 15px auto;
    text-align: center;
  }

  .fp-subforms-remove-btn {
    border: none;
    border-radius: 40px;
    // height: 50px;
    // box-shadow: 0px 2px 3px rgb(46, 43, 44);
    color: crimson;
    float: right;
    cursor: pointer;
    padding: 5px;
    // margin: 15px auto;
    text-align: center;
    &:hover {
      filter: drop-shadow(0 0 10px $emphasis);
    }
  }

  .file-upload-btn-forms {
    background-color: $primary-color;
    padding: 10px;
    color: white;
    width: 200px;
    text-align: center;
    margin: 5px 15px;
    cursor: pointer;
  }
}

.subform-accordian {
  background-color: #d6dfe1;
}
.subform-heading {
  font-size: 2rem;
  flex-basis: 33.33%;
  flex-shrink: 0;
}

.subform-collapse {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #d6dfe1;
}

.not-active-form-message {
  background-color: rgba(255, 255, 255, 0.361);
  width: 100%;
  text-align: center;
  font-size: 2em;
  margin: 19% 0%;
  padding: 1%;
}

.overlay-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  cursor: pointer;

  .loading-submit {
    display: flex;
    flex-direction: column;
    // margin-top: 30%;
    height: inherit;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2rem;
  }
}

@media (min-width: 768px) {
  .forms-questions-wrapper {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .forms-questions-wrapper {
    width: 970px;
  }
}
