.location-layout {
  width: 100%;
  height: 90vh;
  overflow-y: hidden !important;
  display: flex;

  padding: 0;
  margin: 0;
  background-color: white;

  .locationSide {
    background-color: white;
    height: inherit;
    padding: 0;
    width: 100%;
  }

  .usersSide {
    background-color: white;
    border-radius: 2%;
    overflow: hidden;
    height: 85%;
    position: absolute;
    width: 25%;
    min-width: 300px;
    right: 30px;
    top: 80px;

    .searchBar {
      padding: 0.7rem 1.2rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid $light-grey;
      .searchInput {
        width: 80%;
        border: none;
        outline: none;
        height: 2rem;
        font-size: 15px;
      }
      .searchIcon {
        color: $primary-color;
      }
    }

    .backPaper {
      padding: 0.2rem 1rem;
      margin-top: 5px;
      margin-bottom: 10px;
      display: flex;
      cursor: pointer;
      justify-content: space-between;
    }

    .head-label {
      display: flex;
      border-bottom: 1.5px solid $light-grey;
      width: 100%;
      height: 50px;
      justify-content: space-between;
      padding: 8px 10px 8px 0px;
      align-items: center;
      div {
        margin: 0px 5px;
      }
      p {
        margin-top: -1px;
      }
    }

    .userList {
      display: flex;
      flex-direction: column;
      height: 90%;
      overflow-y: auto;
      .userCards {
        margin: 0rem 0.5rem;
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
        border-bottom: 1px solid $light-grey;
        color: #262626;
        background-color: white;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .userCards:hover {
        background-color: $light-grey;
      }

      .offLocation {
        font-size: 17px;
      }

      .arrow {
        width: 20%;
        text-align: center;
        border-radius: 5%;
        background-color: white;
        color: black;
      }

      .arrow:hover {
        color: #4caf50;
        box-shadow: 0px 2px 6px gainsboro;
      }
    }

    .selected {
      color: #4caf50 !important;
      font-weight: bold;
      // background-color: #17263c !important;
    }
  }

  .dragger-location {
    border-top: 4px solid $light-grey;
    border-radius: 10px;
    width: 10%;
    margin: 0% 45%;
    position: absolute;
    top: 5px;
    cursor: n-resize;
  }
}

.padding-15 {
  padding: 0 15px !important;
}

.userName-location {
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 1rem;
  color: black;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  margin-top: 3px !important;
}

.arrow {
  text-align: center;
  border-radius: 5%;
  color: black;
  cursor: pointer;

  &:hover {
    color: $primary-color;
  }
}

.syncButton {
  height: 40px;
  color: #4caf50;
  border: 2px solid #4caf50;
}

.location-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50% 50% 50% 0;
  // background: #4CAF50;
  cursor: pointer;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin-top: -18px;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;

  .avatar-img {
    margin: 5px 0 0 5px;
    position: absolute;
    transform: rotate(+45deg);
  }
}

.link-share-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkbox {
  cursor: copy;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 2px gainsboro;
  padding: 1rem;

  p {
    overflow: auto;
    white-space: nowrap;
    color: $primary-dark;
  }
}

.copyconfirm {
  text-align: center;
  margin-top: '10px';
}

.time-picker-location {
  cursor: pointer;
  width: 80%;
  // margin-top: 5px !important;
}

.meta-icons {
  color: $primary-color;
  font-size: 5px;
}

.meta-info {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  // margin-top: 5px;
  font-size: small;
  padding: 10px 16px;
  text-align: center;
  color: $black;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    span {
      padding-top: 2px;
      padding-left: 2px;
      font-size: 12.5px;
    }
  }
}

.clearIcon {
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.clearIcon:hover {
  background-color: rgba(71, 73, 75, 0.438);
  padding: 2px;
}

.distance-travelled {
  margin-top: -2rem;
}

.switch-timeline {
  width: 20%;
  padding: 2px;
  cursor: pointer;
  color: #4caf50;
  margin: 2px;
  border-radius: 5px;
  box-shadow: 0px 2px 6px #242f3e;
}

.otpDisplay {
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: 32px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  position: relative;
}

.otpWrap {
  // border: 1px solid rgba(0, 0, 0, 0.2);
  display: inline-block;
  position: relative;
  display: flex;
}

.otpShadows {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: 0 0 0 4px rgba(58, 151, 212, 0.28);
}

.otpInput {
  position: absolute;
  border: none;
  font-size: 32px;
  text-align: center;
  background-color: transparent;
  outline: none;
}

.tab-switcher-location {
  width: 100px;
  display: flex;
  justify-content: space-evenly;

  li {
    float: left;
    width: 50px;
    padding: 5px;
    text-align: center;
    list-style: none;
    //   border-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: 0px 2px 6px gainsboro;
  }

  li:hover {
    background-color: gainsboro;
    color: #4caf50;
  }

  .tab-active {
    color: #4caf50;
    border-bottom: 2px solid navy;
  }
}

.back-btn-location {
  cursor: pointer;
  padding-right: 5px;
  border-right: 2px solid gainsboro;
}
.back-btn-location:hover {
  color: navy;
}

.activity-list {
  width: 100%;
  display: flex;
  flex-direction: column;

  div {
    border-bottom: 1px solid $grey;
    background-color: $light-grey;
    padding: 5px 10px;
  }
}

.online {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: $primary-color;
}

.offline {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: rgb(238, 232, 230);
}

.pulse2 {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}

.pulse2:after {
  content: '';
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -50px 0 0 -9px;
  animation: pulsate2 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 2px 4px #4caf50;
  // box-shadow: 0 0 1px 2px #89849b;
  animation-delay: 1.1s;
}

@keyframes pulsate2 {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

.map-container {
  height: inherit;
}

.circleMarker {
  animation: pulsate3 1.3s ease-in;
  animation-iteration-count: infinite;
  position: fixed;
  left: 50%;
  top: 50%;
}

.circleMarker::after {
  position: absolute;
  left: 50%;
  top: 50%;
  animation: pulsate3 1.3s ease-in;
  animation-iteration-count: infinite;
  position: fixed;
}

.pinMarker {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: $primary-color;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;

  .marker-content {
    width: 18px;
    height: 18px;
    margin: 6px 0px 0px 6px;
    background: white;
    position: absolute;
    border-radius: 50%;
    text-align: center;
    transform: rotate(45deg);
  }
}

.startMarker {
  width: 25px;
  height: 25px;
  border-radius: 50% 50% 50% 0;
  background: $color-blue;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;

  &::after {
    content: '';
    width: 10px;
    height: 10px;
    margin: 7px 0px 0px 8px;
    background: white;
    position: absolute;
    border-radius: 50%;
    text-align: center;
    transform: rotate(45deg);
  }
}

.endMarker {
  width: 25px;
  height: 25px;
  border-radius: 50% 50% 50% 0;
  background: $color-red;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  &::after {
    content: '';
    width: 10px;
    height: 10px;
    margin: 7px 0px 0px 8px;
    background: white;
    position: absolute;
    border-radius: 50%;
    text-align: center;
    transform: rotate(45deg);
  }
}

@keyframes pulsate3 {
  0% {
    opacity: 0;
    transform: skew();
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale3d(1.2, 1.2);
  }
}

.noData {
  text-align: center;
  font-size: 1.8rem;
  color: $grey;
  font-weight: bold;
  margin-top: 5rem !important;

  img {
    height: 100px;
    width: 100px;
  }
}

.marker-tooltip {
  width: 300px;
  word-wrap: break-word;
  white-space: normal;
}

.activity-text {
  opacity: 1;
  text-align: center;
  color: white;
  font-size: 1rem;
  padding: 10px;
}

@media screen and (max-width: 600px) {
  .usersSide {
    top: 70% !important;
    width: 100% !important;
    // height: 40% !important;
    // margin: 0% 5% ;
    right: 0px !important;
    box-shadow: 6px 0px 4px $black;
  }

  .head-label {
    padding: 8px 16px;
  }

  .meta-info {
    padding: 16px;
  }
}
