.fp-container {
  text-align: center;
  box-shadow: 0px 3px 6px #00000029;
  background-color: white;
  width: 98%;
  margin: 0.5% 1% !important;
  border-radius: 5px;
  height: 87vh;
  color: #262626;
}

.fp-btn {
  color: white !important;
  margin: 0.5rem 1rem !important;
  width: fit-content;
  text-transform: capitalize !important;
}

.primary-style {
  background-color: $primary-color !important;
}

.secondary-style {
  color: $black !important;
}

.danger-style {
  background-color: $color-red !important;
}

.info-style {
  background-color: $color-blue !important;
}

.outlined-style {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  padding: 4.5px 8px !important;
  border-radius: 50px;
  color: $primary-color !important;
  background-color: white !important;
}

.mini-primary {
  background-color: $primary-color !important;
  color: white !important;
  padding: 2px !important;
}

.no-hover:hover {
  text-decoration: none;
}

.mini-default {
  background-color: $grey !important;
  color: white !important;
  padding: 2px !important;
}

.fp-btn:hover {
  box-shadow: 0px 2px 6px gainsboro;
}

.filter-wrapper {
  text-align: right;
  width: 100%;
  padding-right: 25px;
}

.filterModal {
  width: 600px;
}

.wideScroll::-webkit-scrollbar {
  height: 0.4em;
  border-radius: 10px;
}

.wideScroll::-webkit-scrollbar-thumb {
  background-color: rgba(109, 107, 107, 0.3);
  border-radius: 5px;
}

.scrollFP::-webkit-scrollbar {
  width: 0.4em;
  border-radius: 10px;
}

.scrollFP::-webkit-scrollbar-thumb {
  background-color: rgba(109, 107, 107, 0.3);
  border-radius: 5px;
}

.fp-modal-large {
  // width: 600px;
  max-width: 90%;
}

// .onboard-modal {
//   margin: 5% !important;
//   height: 75%;
//   max-width: 90% !important;
// }

@media screen and (min-width: 576px) {
  .onboard-modal .modal-dialog {
    background-color: aquamarine;
    max-width: 90% !important;
    margin: 0% 5%;
  }
}

@media screen and (max-width: 768px) {
  .fp-modal-large {
    margin: 1% 5% !important;
  }

  .filterModal {
    width: 80% !important;
  }
}

.fp-tabs {
  display: flex;
  text-align: center;
  border-radius: 8px;
  overflow: hidden;
  margin: 5px 10px;
  box-shadow: 0px 2px 6px gainsboro;
  cursor: pointer;
  justify-content: space-evenly;
  font-size: 15px;
}

.fp-tab {
  background-color: $primary-light;
  padding: 1%;
  color: $primary-color;
  transition: background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid $light-grey;
}

.fp-tab-selected {
  color: white;
  padding: 1%;
  transition: background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid $light-grey;
  background-color: $primary-dark;
}

.onBoard-icon {
  margin: 0 10px;
  cursor: pointer;
}

.yet-to-watch {
  color: $primary-dark;
  animation-duration: 600ms;
  animation-name: blink;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.watched {
  color: $grey;
}

.onBoardVideo {
  position: relative;
  // padding-bottom: 32.25%;
  height: 60vh;
  width: 100%;

  .video-play {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.onboard-msg {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // height: inherit;
  padding: 0% 2%;
  text-align: center;
  color: $black;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}

.onboard-url {
  color: $info;
  border: none;
  outline: none;
  display: inline;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.3;
  }
}

.load-wrapper {
  height: auto;
  width: auto;
  display: inline-flex;
  z-index: 30;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.247);
  position: absolute;
  justify-content: center;
  align-items: center;
}

.fp-filter-input {
  width: 100%;
  margin: 10px 0px;
  height: 40px;
  border: 2px solid hsl(0, 0%, 80%);
}

.fp-shadow {
  box-shadow: 0px 2px 6px gainsboro;
  background-color: $primary-color !important;
  cursor: pointer;
}
