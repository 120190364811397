// classic invoice header template css

.invoice-head-classic {
  background-color: rgb(52, 42, 116);
  display: flex;
  padding: 5px;
  min-height: 120px;
  width: 100%;
  line-height: 1.5;
  justify-content: space-between;
}

.invoice-head-classic-left {
  height: inherit;
  padding: 5px;
  width: 60%;
  display: flex;
  color: white;
  flex-direction: column;
}

.invoice-head-classic-right {
  height: inherit;
  padding: 15px 5px;
  width: 40%;
  display: flex;
  text-align: right;
  color: white;
  flex-direction: column;
}

.logo-wrapper {
  display: flex;
  width: 100%;
  min-height: 50px;
  justify-content: flex-start;
  align-items: center;
  justify-items: center;
}

.logo-square {
  height: 50px;
  width: 50px;
}
.invoice-head-classic-logo {
  min-height: 50px;
  border: 2px solid transparent;
}
.invoice-head-classic-company-name {
  font-weight: bold;
  font-size: 20px;
  border: 2px solid transparent;
  margin: 5px;
}

.invoice-head-classic-company-address {
  width: 90%;
  font-weight: 400;
  border: 2px solid transparent;
  // white-space: break-spaces;
}

.hover {
  cursor: pointer;
  margin: 2px;

  border: 2px solid transparent;
}

.hover:hover {
  border: 2px solid #3da15f;
  border-radius: 5px;
}

.active-blk {
  background-color: #e7f9ed;
  border: none !important;
  border-radius: 5px;
}

.active-blk-table {
  color: #3da15f;
  border: 2px solid #3da15f;
}

// invoice-body-template

.invoice-body-classic {
  width: 100%;
  min-height: 400px;
}

.invoice-body-classic-meta {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}

.invoice-body-classic-billto {
  padding: 5px;
  width: 40%;
  border: 2px solid transparent;
}

.invoice-body-classic-deliverto {
  padding: 5px;
  width: 40%;
  text-align: right;
  border: 2px solid transparent;
}

.invoice-body-classic-table {
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
}

.invoice-body-classic-table thead {
  text-align: left;
  border-radius: 10px;
}

.invoice-body-classic-table thead tr {
  background: #342a74;
  color: white;
}

.invoice-body-classic-table thead tr th {
  padding: 15px 20px;
}

.invoice-body-classic-table tbody {
  padding: 20px;
}

.invoice-body-classic-table tbody tr:nth-child(even) {
  background: #fff;
}

.invoice-body-classic-table tbody tr:nth-child(odd) {
  background: #ccc;
}

.invoice-body-classic-table tbody tr td {
  padding: 15px 20px;
}

.invoice-body-classic-table-billTotal {
  float: right;
  width: 30%;
  border: 2px solid transparent;
}

.invoice-footer-classic {
  color: #4caf50;
  font-size: 15px;
  justify-content: space-between;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  height: auto;
}

.invoice-footer-classic-terms {
  padding: 1rem;
  font-size: 10px;
  border: 2px solid transparent;
}

.invoice-footer-classic-signature {
  text-align: right;
  padding: 1rem;
  border: 2px solid transparent;
}
.invoice-footer-classic-fp {
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  font-weight: bold;
  text-align: center;
}
.signature-image {
  height: 50px;
  width: 75px;
}

.left {
  text-align: left;
}
// general css
.settings-header {
  width: 100%;
  border-bottom: 1px solid #c9c8c8;
  font-weight: bold;
  padding: 10px 30px;
}

.select-block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 2rem;
  color: #342a74;
  align-content: center;
}
