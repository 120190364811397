main {
  padding: 0;
}
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@font-face {
  font-family: 'National-Book';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Bold'), url('../fonts/National-Book.ttf') format('woff');
}

@font-face {
  font-family: 'Sailec-Medium';
  src: url('//db.onlinewebfonts.com/t/ac9525e5f200f57332b3080d0db9d8f6.eot');
  src: url('//db.onlinewebfonts.com/t/ac9525e5f200f57332b3080d0db9d8f6.eot?#iefix') format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/ac9525e5f200f57332b3080d0db9d8f6.woff2') format('woff2'),
    url('//db.onlinewebfonts.com/t/ac9525e5f200f57332b3080d0db9d8f6.woff') format('woff'),
    url('//db.onlinewebfonts.com/t/ac9525e5f200f57332b3080d0db9d8f6.ttf') format('truetype'),
    url('//db.onlinewebfonts.com/t/ac9525e5f200f57332b3080d0db9d8f6.svg#Sailec-Medium') format('svg');
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // TODO: Vishal and Karan Check out this font
  font-family: 'Nunito Sans', sans-serif !important;
  // font-family: 'DM-Sans', sans-serif !important;
  font-size: 13px;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;

  @include themify($themes) {
    color: themed('colorText');
  }
}

* {
  box-sizing: inherit;
}

.MuiTableCell-root {
  // TODO: Vishal and Karan Check out this font
  font-family: 'Nunito Sans', sans-serif !important;
  // font-family: 'National-Book', sans-serif !important;
}

ul,
ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $color-blue;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-blue-hover;
  }
}

img {
  width: 100%;
}

.create-question-item-container-box {
  display: inline-block;
  position: relative;
  min-height: 85px;
  width: 100%;
  border-bottom: 1px dashed #dddfe8;

  background: white;

  &.row {
    margin-left: 0px;
  }
}

.create-question-item-container-box {
  .show-add-button {
    display: none;
  }
}

.create-question-item-container-box-selected {
  display: inline-block;
  position: relative;
  min-height: 115px;
  width: 100%;

  padding: 2px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 10px;
  background: white;
  z-index: 5;

  &.row {
    margin-left: 0px;
  }
}

.padding-30 {
  padding: 17px;
}

.btn.btn-outline-secondary:hover {
  background: transparent;
  border-color: #e7e2e2;
  color: transparent;
}

.btn.btn-outline-secondary {
  background: transparent;
  border-color: transparent;
  color: transparent;
}

// .create-question-container {
//   display: inline-block;
//   height: 100%;
//   width: 100%;
//   border-radius: 10px;
//   box-shadow: 0 1px 15px rgba(0, 0, 0, 0.12), 0 1px 16px rgba(0, 0, 0, 0.24);
//   background: white;
// }
