// .task-view .MuiSvgIcon-root {
//   fill: #4caf50 !important;
// }

.response-table .material-table__cell--sort {
  border: 1px solid var(--color) !important;
}

.cancelIcon {
  width: 20px;
  height: 20px;
}

.cancel-card {
  min-width: 150px;
  min-height: 61px;
  padding: 5px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: gainsboro;
  color: crimson;
  cursor: context-menu;
  margin: 1rem 0rem;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .cancel-info {
    text-align: center;
    width: 200px;
    padding: 5px;

    p {
      color: crimson;
    }
  }
}
