@import 'settings/variable'; //themes' colors

@mixin firefox-only {
  @at-root {
    @-moz-document url-prefix() {
      & {
        @content;
      }
    }
  }
}
//base...
@import 'generic/normalize.scss';
@import 'generic/box-sizing.scss';
@import './base/scaffolding'; // styles of base elements
@import './base/typography';
// base styles of h1-h6, p, span

//components...
@import 'component/btn';
@import './component/card';
@import './component/check-box';
@import './component/taskResponseView.scss';
@import './component/sidebar';
@import './component/topbar';
@import './component/load';
@import './component/form';
@import './component/dropzone';
@import './component/create-tab.scss';
@import './component/dashboard.scss';
@import './component/datepicker.scss';
@import './component/react-style.scss';
@import './component/workflow.scss';
@import './component/reactGrid.scss';
@import './component/permissions.scss';
@import './component/chat.scss';
@import './component/scroll.scss';
@import './component/tabs.scss';
@import './component/team.scss';
@import './base/color.scss';
@import './component/profile.scss';
@import './component/loaction-tracker.scss';
@import './component/material-table';
@import './component/pdf/custompdf.scss';
@import './component/pdf/deliveryPdf.scss';
@import './component/live-location.scss';
@import './component/forms-fp.scss';
@import './component//dataset.scss';
@import './component/reports.scss';
@import './component/response.scss';
@import './component/timeline.scss';
@import './component/fpComponents.scss';
@import './component/fp-table.scss';
@import './generic/generalStyles.scss';
//pages...
@import 'containers/account';

//objects...
@import 'objects/layout.scss';

@import './base/switch.min.css';

//Typography
@import './typography.scss';
