.delivery-pdf-header {
  display: flex;
  padding: 5px;
  width: 100%;
  line-height: 1.5;
  color: black;
  justify-content: space-between;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
}

.delivery-pdf-head-left {
  height: inherit;
  padding: 10px 5px;
  width: 30%;
  color: black;
}

.distributor-pdf-title {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #4caf50;
  word-break: normal;
}

.delivery-pdf-head-right {
  height: inherit;
  padding: 10px 5px;
  width: 60%;
  display: flex;
  text-align: right;
  color: black;
  justify-content: flex-start;

  .delivery-pdf-logo {
    width: 150px;
    height: 150px;
    text-align: center;
    padding: 5px;
  }

  .delivery-pdf-head-right-content {
    width: auto;
    height: inherit;
    text-align: left;
    color: #4caf50;
    font-size: 15px;

    .delivery-pdf-head-company-name {
      font-weight: bold;
      font-size: 15px;
    }
  }
}

.delivery-logo-square {
  height: 100px;
  width: 100px;
}

.delivery-body {
  color: #4caf50;
  font-size: 15px;
  justify-content: space-between;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;

  .delivery-pdf-table-container {
    padding: 15px;
  }

  table {
    color: black;
    width: 100%;
    height: auto;
    &:hover {
      cursor: pointer;
    }
  }

  th {
    color: #3da15f;
  }

  td,
  th {
    padding: 5px;
    border: 1.5px solid #4caf50;
  }

  .delivery-pdf-total {
    td {
      border: 1px solid #4caf50;
      padding: 5px;
      margin-bottom: 5px;
    }
    td:empty {
      visibility: hidden;
      border: none;
    }
  }

  .active-delivery-pdf-table {
    th {
      background-color: #e7f9ed;
    }
  }
  .active-delivery-pdf-table-cell {
    border: 2px solid #4caf50;
    background: #e7f9ed;
  }
}

.pdf-headers {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #4caf50;
  word-break: normal;
}
