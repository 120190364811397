/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  // padding: 20px 0px;
  margin: 0 auto;
  min-height: 100%;
  color: $black;
  overflow-y: auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: '';
  position: absolute;
  width: 4px;
  background-color: $light-grey;
  top: 0;
  bottom: 0;
  left: 30px;
  margin-left: -4px;
}

/* Container around content */
.timeline-container {
  padding: 10px 25px 20px 60px;
  position: relative;
  background-color: rgb(255, 255, 255);
  width: 100%;
  // padding-left: 70px;
  // padding-right: 25px;
}

.timeline-side-icons {
  color: $primary-color;
  background-color: white;
  box-shadow: 0px 2px 6px gainsboro;
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 2;
}

.timeline-side-icons-active {
  color: white;
  background-color: $primary-color;
  box-shadow: 0px 2px 6px gainsboro;
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 2;
}
/* The circles on the timeline */
.timeline-container::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0px;
  background-color: white;
  border: 10px solid $light-grey;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.timeline-container-active::after {
  border: 10px solid $primary-color !important;
}

/* Place the container to the right */
.timeline-right {
  left: 0;
}

/* Fix the circle for containers on the right side */
.timeline-right::after {
  left: 18px;
}

/* The actual content */
.timeline-content {
  padding: 5px;
  width: 100%;
  cursor: pointer;
  background-color: inherit;
  position: relative;
  border-radius: 6px;
  border: 2px solid transparent;
  box-shadow: 0px 2px 6px gainsboro;
}

.timeline-content:hover {
  background-color: $light-grey;
}

.timeline-container::before {
  left: 60px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

.timeline-active {
  border: 2px solid $primary-color;
  font-weight: 450;
}

.timeline-active:hover {
  background-color: white;
}

.timeline-meta {
  display: flex;
  align-items: center;
  width: 100%;
  margin: -1% 0%;
  color: #262626;
}

.timeline-meta-info {
  color: #262626;
  width: auto;
  font-size: 10px;
  // padding-left: 5px;
}

.timeline-content-text {
  color: $black;
  font-size: 15px;
  font-weight: 400;
}

.timeline-taskName {
  color: $primary-color;
  cursor: pointer;
  font-weight: bold;
}

.timeline-tab {
  padding: 0px 6px;
}

@media screen and (max-width: 600px) {
  .timeline-tab {
    padding: 0px 16px;
  }
}
