.link-arrow {
  margin: 0 9px;
  color: #5f6c72;
  width: 7px;
  height: 7px;
  position: relative;
  top: 0.55rem;
  fill: currentColor;
}

.date_picker .MuiIconButton-label {
  color: #4caf50 !important;
}

.date_picker .MuiInputBase-root {
  font-size: 12px !important;
}

.identifier_select .MuiNativeSelect-select {
  background-color: white !important;
  font-size: 15px;
}

.identifier_select .css-1laao21-a11yText {
  border: none !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.identifier_select .css-yk16xz-control {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #ddd !important;
  border-radius: 0px !important;
}

.identifier_select .css-1pahdxg-control {
  box-shadow: none !important;
}

.identifier_select .css-2b097c-container {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.identifier_select .css-1okebmr-indicatorSeparator {
  display: none !important;
}

.identifier_select .css-1pahdxg-control:hover {
  outline: none !important;
  border: none !important;
  border-bottom: #2684ff !important;
}

.identifier_select .css-g1d714-ValueContainer {
  font-size: 15px !important;
}

.indentifier-card {
  min-width: 150px;
  height: 61px;
  box-shadow: 0px 3px 6px #00000029;
  margin: 1rem 0rem;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pdf-card {
  min-width: 120px;
  color: white;
  text-align: center;
  height: 61px;
  box-shadow: 0px 3px 6px #00000029;
  margin: 1rem 0rem;
  // border-right: 10px transparent;
  border-top-right-radius: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pdfResponseCard {
  width: 250px;
  height: 250px;
  background-color: #4caf50;
  border-top-right-radius: 50px;
  color: white;
  text-align: center;
  padding: 100px 0px;
  font-weight: 500;
  font-size: 1.2rem;
  a {
    color: white;
  }
  p {
    color: white;
  }
}
.allocation .MuiSvgIcon-root {
  fill: #4caf50 !important;
}

.dataset .btn.btn-secondary {
  background-color: inherit !important;
}

.link_page {
  transition: all 0.8s ease 3s;
}

.link_page .MuiTypography-body2 {
  font-size: 13px !important;
}

.workflow-list .MuiSelect-icon {
  color: #4caf50 !important;
}

.allocation .MuiSelect-select.MuiSelect-select {
  font-size: 12px !important;
}

.workflow-table .card-body {
  padding: 2rem !important;
}

.workflow-table .material-table__row:hover {
  background-color: inherit !important;
}

.workflow-table .dropdown__menu {
  width: 100px !important;
}

.workflow-div {
  display: block;
  height: 100px;
  width: 600px;
  div {
    width: 100px;
    height: inherit;
    display: inline-block;
    background-color: lightblue;
  }
}

.template-box {
  width: 195px;
  margin-right: 24px;
  position: relative;

  img {
    border-radius: 5px;
    height: 110px;
    margin-bottom: 16px;
    object-fit: cover;
    box-shadow: 0px 3px 6px #00000029;
  }

  button {
    padding: 4px 20px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 700;
  }

  .template-box-show-more {
    background-color: #fff;
    border: 1px solid $primary-color;
    color: $primary-color;
  }

  .template-box-use-me {
    margin-top: 12px;
    background-color: $primary-color;
    color: #fff;
  }

  .template-box-hover-buttons-container {
    position: absolute;
    display: flex;
    width: inherit;
    height: 110px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $primary-color-hover;
    transition: 0.5s ease;
    border-radius: 5px;
    opacity: 0;
  }

  &:hover {
    .template-box-hover-buttons-container {
      opacity: 1;
    }
    span {
      font-weight: 700;
      color: $primary-color;
    }
  }
}

.template-box-shadow:hover,
.template-box-shadow:active {
  box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.workflow-div-head {
  display: block;
  height: 50px;
  width: 600px;
  // overflow-x: scroll;
  div {
    width: 100px;
    display: inline-block;
    background-color: lightcoral;
  }
}

.row-headers {
  font-weight: bolder;
  height: 80px;
  padding-top: 12px;
}
.row-headers-2 {
  font-weight: bolder;
  text-align: center;
  height: 80px;
  padding-top: 12px;
  border-left: 2px solid gainsboro;
}

.row-body {
  height: 80px;
  p {
    width: 100px;
  }
}

.row-body-2 {
  height: 80px;
  border-left: 2px solid gainsboro;
}
.row-header-workflow {
  th {
    text-align: left;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.row-body-workflow {
  height: 80px;
  td {
    height: 80px;
    p {
      min-width: 120px;
    }
  }
}

.action {
  th {
    text-align: center;
  }
  text-align: center;
  border-left: 2px solid gainsboro;
}
.primaryIdentifier {
  border-right: 2px solid gainsboro;
}

.search-box {
  border: none;
  margin-top: 2em;
  border-bottom: 1.5px solid #00000029;
  outline: none;
  width: 80%;
  height: 2em;
  padding-bottom: 5px;
}

.activeTab {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  border: none;
  margin-right: 3px;
  cursor: pointer;
}

.inactiveTab {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: gainsboro;
  margin-right: 3px;
  border: none;
  cursor: pointer;
}

.closeBar {
  padding-left: 10px;
  font-size: 5px;
}
.closeBar:hover {
  color: red;
  box-shadow: 1px 2px solid gainsboro;
}

//scss change
._3vt7_Mh4hRCFbp__dFqBCI li:hover {
  background: #4caf50 !important;
}

.lhyQmCtWOINviMz0WG_gr {
  background: white !important;
  color: black !important;
}

.responseGallery {
  width: 150px;
  height: 150px;
  margin: 5px;
  border-radius: 10px;
}

.responseGallery2 {
  width: 50px;
  display: inline-block;
  height: 50px;
  margin: 2px;
  border-radius: 10px;
}

.signature {
  width: 500px;
  height: 300px;
  //   margin: 5px;
  border-radius: 10px;
}

.responseGallery:hover {
  cursor: pointer;
  border: 2px solid seagreen;
}

.location-text {
  color: #4caf50;
  text-shadow: 0.5px 0.5px white;
}

.user-table {
  min-width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  white-space: nowrap;
  background-color: white;
  tr {
    td {
      word-wrap: break-word;
      height: 50px;
      width: 200px;
      text-align: center;
      overflow-y: auto;
    }
    th {
      height: 30px;
      width: 200px;
      text-align: center;
      overflow-y: auto;
    }
  }
}

.tableCells {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  border: 0.8px solid black;
}

.user-header {
  background: #4caf50;
  color: white;
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 30px;
  padding: 5px;
  z-index: 5;
}

.user-body {
  overflow-y: auto;
  background-color: white;
}

.geo-marker {
  cursor: pointer;
  color: #4caf50;
  font-size: 5.5rem;
}

.geo-marker-selected {
  color: red;
  cursor: pointer;
  z-index: 20;
  border-radius: 10px;
}
.geo-marker:hover {
  color: red;
}

.resp-highligted {
  margin: 0.5em 0;
  padding: 0.5em;
  text-align: left;
  opacity: 1;
  border: 3px solid $emphasis;
  box-shadow: none !important;
}

.resp-map {
  margin: 0.5em 0;
  padding: 0.5em;
  text-align: left;
  opacity: 0.6;
}

.clickable {
  color: $success;
  font-weight: bold;
  cursor: pointer;
  margin-right: 5px;
}

.pointer {
  cursor: pointer;
}

.dateStamp {
  position: relative;
  left: 4rem;
  width: 3.5rem;
  font-size: 0.8rem;
  white-space: wrap;
  word-wrap: break-word;
  top: -2.5rem;
}

.dateStamp2 {
  position: relative;
  left: 4rem;
  width: 4.5rem;
  font-size: 0.8rem;
  white-space: wrap;
  word-wrap: break-word;
  top: -1.85rem;
}

.bold {
  font-weight: 600 !important;

  p {
    font-weight: 600 !important;
  }
}

.workflow-span-badge {
  padding: 2px 8px;
  border-radius: 2px;
  height: 200px !important;
  width: 100px !important;
  margin-left: 15px;
  font-size: 13px;
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.respTableCell {
  min-width: 130px;
  position: sticky;
  top: 0;
  z-index: 70;
}

.url-cursor {
  cursor: url(../assets/images/openurl.png), auto;
}

.url-cursor:hover {
  background-color: var(--color) !important;
}

.flowchart-float {
  z-index: 100;
  top: 0px;
  height: 99px;

  background-color: transparent;
  overflow-y: auto;
  margin: 5px;
  display: flex;
  border-radius: 14px;
}

.workflow-info-addbox {
  // cursor: pointer;
  // width: fit-content;
  // margin-top: 10px;
  // margin-left: 15px;
  // display: inline-block;
  cursor: pointer;

  width: -moz-fit-content;
  min-width: 100px;
  margin-top: 10px;
  margin-left: 15px;
  display: inline-table;
}

.toggle-switch-response {
  position: absolute !important;
  background-color: #4caf50 !important;
  color: white !important;
  top: 50%;
  box-shadow: 0px 2px 6px rgb(102, 100, 100);
  z-index: 105;
  transition: transform 2s;
}

.toggle-switch-response:hover {
  transform: rotate(180deg);
}

.side-close {
  left: 50px;
}

.side-open {
  left: 22%;
}

.rootTaskBar {
  display: flex;
  width: 100%;
  max-height: 400px;
  justify-content: flex-start;

  .rootTaskLeft {
    width: 15%;
    height: auto;
  }
  .rootTaskRight {
    width: 80%;
    max-height: 400px;
    overflow: auto;
  }
}

.timelineRound {
  width: 50px;
  min-height: 50px;
  background-color: #4caf50;
  border-radius: 100%;
  position: relative;
  left: 0.5rem;
}

.connector-timeline {
  width: 5px;
  height: 100%;
  margin: -4rem auto;
  background-color: #4caf50;
}
