.dashboard_view .material-table__cell--sort {
  padding: 1 10px !important;
}

.dashboard_view .MuiTableCell-root {
  border: 1px solid #4caf50 !important;
  border-left: none !important;
  border-top: none !important;
  border-bottom: none !important;
}

.custom-scroll::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #888;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dashboard__chart-legend {
  list-style: none;
  float: left;
  margin-left: 5px;
  span {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  p {
    display: inline-block;
  }
}

.reminder-block {
  height: 450px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  text-align: center;
  padding: 0rem 2rem 2rem 2rem;
}

.agent-container {
  padding: 0% 5%;
}
