.loc-popup {
  background-color: white;
  padding: '10px';
  border-radius: 10px;
}

.rdg-editor-container {
  background-color: white;
}

.import-data-btn {
  margin-top: 8px;
  margin-right: 16px;
  background-color: $primary-color;
  color: #fff;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 6px;
  padding-top: 6px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
