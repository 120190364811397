.permissions .MuiTabs-centered {
  justify-content: space-around !important;
}

.permissions .MuiPaper-rounded {
  margin-top: -1rem !important;
}

.permissions .MuiTab-root {
  min-width: 0px !important;
}
